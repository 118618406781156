const EventConstants = {
  tennisCosts: [
    {
      label: "Adult Singles Cost",
      name: "adult-singles",
      cost: 0,
      touched: false,
      error: false,
    },
    {
      label: "Adult Doubles Cost",
      name: "adult-doubles",
      cost: 0,
      touched: false,
      error: false,
    },
    {
      label: "Youth Singles Cost",
      name: "youth-singles",
      cost: 0,
      touched: false,
      error: false,
    },
    {
      label: "Youth Doubles Cost",
      name: "youth-doubles",
      cost: 0,
      touched: false,
      error: false,
    },
    {
      label: "Kids Singles Cost",
      name: "kids-singles",
      cost: 0,
      touched: false,
      error: false,
    },
  ],
}

// const skillLevels = [
//   { name: 'Beginner', value: 1 }, 
//   { name: 'Medium', value: 2 }, 
//   { name: 'Advanced', value: 3 }
// ]

const skillLevels = [
  { name: '2.00 - 2.99 [Novice]', value: 1 }, 
  { name: '3.00 - 3.99 [Intermediate]', value: 2 }, 
  { name: '4.00 - 4.99 [Advanced]', value: 3 },
  { name: '5.00 - 8.00 [Professional]', value: 4 }
]

export { EventConstants, skillLevels }
