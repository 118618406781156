import { useFormik } from "formik"
import * as Yup from "yup"
import TextField from "../../../../components/TextField"
import SelectMenu from "../../../../components/SelectMenu"
import { useEffect, useRef, useState } from "react"
import commonFunctions from "utils/CommonFunctions"
import Button from "components/Button"
import RadioButton from "components/RadioButton"
import Table from "../../../../components/Table/Table"
import AddTeammate from "../AddTeammate"

const Badminton = ({ registerEvent, event = null }) => {
  const [category, setCategory] = useState(null)
  const [categoryError, setCategoryError] = useState(false)
  const [registerThrough, setRegisterThrough] = useState(null)
  const costType = "default"
  const [acceptCondition, setAcceptConditions] = useState(false)
  const [acceptConditionError, setAcceptConditionsError] = useState(false)
  const [skillLevel, setSkillLevel] = useState(null)
  const [skillLevelError, setSkillLevelError] = useState(null)
  const [allFieldsValidated, setAllFieldsValidated] = useState(false)
  const adultDoublesRef = useRef(null)
  const [players, setPLayers] = useState([])
  const [openAddPlayer, setOpenAddPlayer] = useState(false)

  const columns = [
    {
      name: "Firstname",
      selector: "firstName",
      cell: (row) => <span>{row.firstName}</span>,
    },
    {
      name: "Lastname",
      selector: "lastName",
    },
    {
      name: "Contact number",
      selector: "contactNumber",
    },
    {
      name: "Actions",
      selector: "action",
      style: { width: "40px" },
      cell: (row, index) => (
        <div className="flex items-center space-x-2">
          <button
            title="Mark As Unread"
            onClick={(e) => {
              e.preventDefault()
              handleRemovePLayer(index)
            }}
            className="text-gray-700 px-2 py-1 border border-gray-300 rounded-lg shadow hover:bg-gray-200 transition duration-100"
          >
            Remove
          </button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (adultDoublesRef.current) {
      adultDoublesRef.current.click()
    }
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      primaryContactName: "",
      email: "",
      confirmEmail: "",
      phone: "",
      alternatePhone: "",
    },
    validationSchema: Yup.object().shape({
      primaryContactName: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      confirmEmail: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("email"), null], "Email must match"),
      phone: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (category === null) {
        setCategoryError(true)
      } else {
        if (!allFieldsValidated) return

        values["players"] = players
        // values["eventCost"] = event.eventCost[costType]
        values["eventCost"] = category === 'singles' ? 30 : 60
        values["registerThrough"] = registerThrough
        values["teamType"] = category
        values["phone"] = commonFunctions.phoneNumberToText(values["phone"])
        values["alternatePhone"] = commonFunctions.phoneNumberToText(values["alternatePhone"])
        values["acceptCondition"] = acceptCondition
        values["skillLevel"] = skillLevel ? skillLevel?.value : 1

        if (event.webUrl && !acceptCondition) {
          setAcceptConditionsError(true)
        } else {
          registerEvent(values)
        }
      }
    },
  })

  const validateOtherFields = () => {
    let hasIssues = false

    if (skillLevel == null || skillLevel?.value === null) {
      setSkillLevelError("Required")
      hasIssues = true
    }

    setAllFieldsValidated(!hasIssues)

    return hasIssues
  }

  const handleAddPlayer = (player) => {
    setPLayers([...players, player])
  }

  const handleRemovePLayer = (index) => {
    let newPlayers = [...players]
    newPlayers.splice(index, 1)
    setPLayers(newPlayers)
  }


  return (
    <section>
      <AddTeammate open={openAddPlayer} setOpen={setOpenAddPlayer} handleAddPlayer={handleAddPlayer} />

      <form className="space-y-4 md:space-y-6">
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div>
            <TextField
              label="Name of primary contact"
              name="primaryContactName"
              type="text"
              value={formik.values.primaryContactName}
              onChange={formik.handleChange}
              error={formik.errors.primaryContactName}
              touched={formik.touched.primaryContactName}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
          <SelectMenu
              label="Skill Level"
              name="skillLevel"
              // items={[
              //   { name: '1.0 - 2.0 [Beginner]', value: 1 }, 
              //   { name: '2.5 - 3.5 [Medium]', value: 2 }, 
              //   { name: '4.0 + [Advanced]', value: 3 }
              // ]}
              items={[
                { name: '2.00 - 2.99 [Novice]', value: 1 }, 
                { name: '3.00 - 3.99 [Intermediate]', value: 2 }, 
                { name: '4.00 - 4.99 [Advanced]', value: 3 },
                { name: '5.00 - 8.00 [Professional]', value: 4 }
              ]}
              value={skillLevel?.value}
              onChange={(data) => {
                setSkillLevel(data)
                setSkillLevelError(null)
              }}
              hasError={skillLevelError}
              error={skillLevelError}
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div>
            <TextField
              label="Email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              touched={formik.touched.email}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
            <TextField
              label="Confirm Email"
              name="confirmEmail"
              type="text"
              value={formik.values.confirmEmail}
              onChange={formik.handleChange}
              error={formik.errors.confirmEmail}
              touched={formik.touched.confirmEmail}
              onBlur={formik.handleBlur}
              required
            />
          </div>

          <div>
            <TextField
              label="Phone number"
              name="phone"
              type="text"
              placeholder="(XXX) XXX-XXXX"
              phoneNumberFormat={true}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
              touched={formik.touched.phone}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
            <TextField
              label="Alternate phone number"
              name="alternatePhone"
              type="text"
              placeholder="(XXX) XXX-XXXX"
              phoneNumberFormat={true}
              value={formik.values.alternatePhone}
              onChange={formik.handleChange}
              error={formik.errors.alternatePhone}
              touched={formik.touched.alternatePhone}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>

        <div>
          <label htmlFor={"category"} className="block text-sm font-medium text-gray-700 mb-3">
            Participant Category
            <span className="text-md text-red-500">*</span>
          </label>
          <div className="flex items-center mb-2">
            <RadioButton
              name="singles"
              value="singles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Singles ($30)
            </RadioButton>
          </div>
          <div className="flex items-center mb-2">
            <RadioButton
              name="doubles"
              value="doubles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Doubles ($60)
            </RadioButton>
          </div>
          {/* {!event.webUrl && <div className="flex items-center mb-2">
            <RadioButton
              name="adult-singles"
              value="adult-singles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Adult Singles
            </RadioButton>
          </div>}
          <div className="flex items-center mb-2">
            <RadioButton
              name="adult-mens"
              value="adult-mens"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Adult Men's Doubles
            </RadioButton>
          </div>
          <div>
          <RadioButton
              name="adult-womens"
              value="adult-womens"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Adult Women's Doubles
            </RadioButton>
          </div>
          {!event.webUrl && <div className="flex items-center mb-2">
            <RadioButton
              name="youth-singles"
              value="youth-singles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Youth Singles
            </RadioButton>
          </div>}
          {!event.webUrl && <div className="flex items-center mb-2">
            <RadioButton
              name="youth-doubles"
              value="youth-doubles"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Youth Doubles
            </RadioButton>
          </div>} */}
          {categoryError && <span className="text-red-600 text-xs">Required</span>}
        </div>

        <div>
          <label htmlFor={"category"} className="block text-sm font-medium text-gray-700 mb-3">
            Team Info
          </label>
          <Button
            onClick={() => {
              if (players.length >= event?.maxPlayers) {
                alert("You can only add up to " + event?.maxPlayers + " players. ")
              } else {
                setOpenAddPlayer(true)
              }
            }}
          >
            Add Player
          </Button>
          <div className="text-sm">You can add players up to {event?.maxPlayers}</div>

          <Table columns={columns} data={players} loading={false} disableFooter={true} />
        </div>
      </form>



      {event.webUrl && <div className="w-full mt-6">
          <span className="font-bold">Waiver URL:</span>{" "}
          <div>
            <a href={event.webUrl} target="_blank" className="text-blue-600">{event.webUrl}</a>
          </div>

          <div className="mt-5">
            {/* <Button
              onClick={() => {
                setAcceptConditions(!acceptCondition)
                if (!acceptCondition) {
                  setAcceptConditionsError(false)
                }
              }}
              variant={acceptCondition ? 'primary': 'secondary'}
            >
              Sign wavier
            </Button> */}

            <div className="flex flex-col">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="membership"
                      name="membership"
                      aria-describedby="membership"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                      required=""
                      onChange={() => {
                        setAcceptConditions(!acceptCondition)
                        if (!acceptCondition) {
                          setAcceptConditionsError(false)
                        }
                      }}
                      checked={acceptCondition}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="membership" className="text-gray-500">
                      I have signed the waiver.
                    </label>
                  </div>
                </div>
              </div>

            {/* {acceptCondition && <div className="text-green-600 mt-2">You signed wavier</div>} */}
          </div>
        </div>}

        {acceptConditionError && <div className="text-center text-red-600">
          Please sign wavier
        </div>}

      <div className="bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
        <Button
          onClick={() => {
            validateOtherFields()
            setRegisterThrough("OFFLINE")
            formik.handleSubmit()
          }}
          className="ml-3"
        >
          Register Offline
        </Button>
        <Button
          onClick={() => {
            validateOtherFields()
            setRegisterThrough("PAYPAL")
            formik.handleSubmit()
          }}
        >
          Register With Paypal
        </Button>
      </div>
    </section>
  )
}

export default Badminton
