import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ApiService from "../../../services/ApiService"
import Loader from "../../../components/Loader"
import { useDispatch } from "react-redux"
import { addError } from "../../../redux/pageErrorHandler"
import Table from "../../../components/Table/Table"
import AddTeammate from "../../Events/EventRegistration/AddTeammate"
import useToast from "hooks/useToast"
import moment from "moment"
import { skillLevels } from "../../Events/constants"

const ViewTeam = () => {
  const dispatch = useDispatch()
  const { renderSuccessToast } = useToast()
  const { eventRegId } = useParams()
  const [eventType, setEventType] = useState(null)
  const [category, setcategory] = useState(null)

  const columns = [
    {
      name: "Firstname",
      selector: "firstName",
      cell: (row) => <span>{row.firstName}</span>,
    },
    {
      name: "LastName",
      selector: "lastName",
    },
    {
      name: "Contact number",
      selector: "contactNumber",
    },
    {
      name: "DUPR Ranking",
      selector: "skillLevel",
      cell: row => <span>{getSkillLevel(row.skillLevel).toString()}</span>
    },
    // {
    //   name: "Date of birth",
    //   selector: "dob",
    //   cell: row => <span>{row.dob ? moment(row.dob).format('DD-MMM-YYYY') : ""}</span>
    // },
    // {
    //   name: "Grade",
    //   selector: "grade",
    // },
    {
      name: "Actions",
      selector: "action",
      style: { width: "40px" },
      cell: (row, index) => (
        <div className="flex items-center space-x-2">
          <button
            title="Mark As Unread"
            onClick={(e) => {
              e.preventDefault()
              handleRemovePLayer(index)
            }}
            className="text-gray-700 px-2 py-1 border border-gray-300 rounded-lg shadow hover:bg-gray-200 transition duration-100"
          >
            Remove
          </button>
        </div>
      ),
    },
  ]

  const getSkillLevel = (level) => {
    return `${skillLevels.filter(s => s.value == level)[0].name} - ${level}`
  }

  const [teamResults, setTeamResults] = useState({
    loading: false,
    errror: false,
    message: "",
    data: null,
  })
  const [updateTeamResults, setUpdateTeamResults] = useState({
    loading: false,
    errror: false,
    message: "",
    data: null,
  })
  const [team_members, set_team_members] = useState([])
  const [openAddPlayer, setOpenAddPlayer] = useState(false)
  let message = ""

  useEffect(() => {
    getTeamDetails()
    // eslint-disable-next-line
  }, [])

  const getTeamDetails = async () => {
    try {
      setTeamResults({ loading: true, errror: false, message: "", data: null })
      let res = await ApiService.getTeamDetails(eventRegId)
      setEventType(res?.data?.team_details?.event?.eventType?.value)
      setcategory(res?.data?.team_details?.event_registration?.teamType)
      setTeamResults((prev) => ({
        ...prev,
        loading: false,
        data: res.data?.team_details || null,
      }))
      set_team_members(res.data?.team_details?.team || [])
    } catch (error) {
      let message = error.response.data.message
      dispatch(addError(message))
      setTeamResults((prev) => ({
        ...prev,
        loading: false,
        message: message,
        errror: true,
      }))
    }
  }

  const updateTeam = async () => {
    try {
      setUpdateTeamResults({ loading: true, errror: false, message: "", data: null })
      await ApiService.updateTeam(eventRegId, team_members)
      setUpdateTeamResults((prev) => ({
        ...prev,
        loading: false,
      }))
      getTeamDetails()
      renderSuccessToast("Team Updated")
    } catch (error) {
      let message = error.response.data.message
      dispatch(addError(message))
      setUpdateTeamResults((prev) => ({
        ...prev,
        loading: false,
        message: message,
        errror: true,
      }))
    }
  }

  const handleAddPlayer = (player) => {
    let player_chk_str = `${player["firstName"].trim()} ${player["lastName"].trim()} ${
      player["contactNumber"]
    }`
    let flag = false
    for (let i = 0; i < team_members.length; i++) {
      let tm = team_members[i]
      let each_str = `${tm["firstName"].trim()} ${tm["lastName"].trim()} ${tm["contactNumber"]}`
      if (each_str === player_chk_str) {
        flag = true
        message = "Your already added this player."
        break
      }
    }

    if (!flag) {
      set_team_members([...team_members, player])
    }
  }

  const handleRemovePLayer = (index) => {
    let newPlayers = [...team_members]
    newPlayers.splice(index, 1)
    set_team_members(newPlayers)
  }

  return (
    <div>
      <Loader open={teamResults.loading || updateTeamResults.loading} />
      <AddTeammate
        open={openAddPlayer}
        setOpen={setOpenAddPlayer}
        handleAddPlayer={handleAddPlayer}
        message={message}
        eventType={eventType}
        category={category}
      />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Team Details</h1>
        </div>
      </header>

      <div className="mx-8 my-4">
        <div className="bg-yellow-200 px-2 ml-1 py-1 mb-4 rounded text-yellow-800 inline-block">
          Team Name: <b> {teamResults?.data?.event_registration?.teamName} </b>
        </div>
        <br />

        <button
          type="button"
          // onClick={() => setOpenAddPlayer(true)}
          onClick={() => {
            if (team_members.length >= teamResults.data?.event?.maxPlayers) {
              alert("You can only add up to " + teamResults.data?.event?.maxPlayers + " players. ")
            } else {
              setOpenAddPlayer(true)
            }
          }}
          className={`bg-[#10a37f] hover:opacity-80 text-[#fff] border-none rounded-md box-border cursor-pointer inline-flex font-normal justify-center leading-5 items-center transition duration-300 ease-in-out select-none focus:outline-none focus:shadow-outline-blue text-sm px-5 py-2 w-auto`}
        >
          Add Player
        </button>
        <div className="text-sm">You can add players up to {teamResults.data?.event?.maxPlayers}.</div>

        <Table columns={columns} data={team_members} />

        <div className="bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={updateTeam}
            className={`bg-[#10a37f] hover:opacity-80 text-[#fff] border-none rounded-md box-border cursor-pointer inline-flex font-normal justify-center leading-5 items-center transition duration-300 ease-in-out select-none focus:outline-none focus:shadow-outline-blue text-sm px-5 py-2 w-auto`}
          >
            Update Team
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewTeam
